import * as a from "react";
import { j as i } from "./jsx-runtime-B6kdoens.js";
import "react-dom";
import { c as S, A as le, C as fe, G as me, L as we, I as ge, a as xe, R as ve, b as he, d as be, S as ye, e as Me, f as Re, g as Ne, h as De, P as Se, i as Ce } from "./index-vsIpK2ZN.js";
import { c as m } from "./utils-CJ9afRe1.js";
import { I as M } from "./Icon-bQEUFl7D.js";
function h(e, o, { checkForDefaultPrevented: r = !0 } = {}) {
  return function(t) {
    if (e == null || e(t), r === !1 || !t.defaultPrevented)
      return o == null ? void 0 : o(t);
  };
}
function _e(e, o) {
  typeof e == "function" ? e(o) : e != null && (e.current = o);
}
function C(...e) {
  return (o) => e.forEach((r) => _e(r, o));
}
function Ie(e, o = []) {
  let r = [];
  function n(s, c) {
    const d = a.createContext(c), u = r.length;
    r = [...r, c];
    const l = (f) => {
      var N;
      const { scope: w, children: b, ...x } = f, v = ((N = w == null ? void 0 : w[e]) == null ? void 0 : N[u]) || d, pe = a.useMemo(() => x, Object.values(x));
      return /* @__PURE__ */ i.jsx(v.Provider, { value: pe, children: b });
    };
    l.displayName = s + "Provider";
    function g(f, w) {
      var v;
      const b = ((v = w == null ? void 0 : w[e]) == null ? void 0 : v[u]) || d, x = a.useContext(b);
      if (x) return x;
      if (c !== void 0) return c;
      throw new Error(`\`${f}\` must be used within \`${s}\``);
    }
    return [l, g];
  }
  const t = () => {
    const s = r.map((c) => a.createContext(c));
    return function(d) {
      const u = (d == null ? void 0 : d[e]) || s;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...d, [e]: u } }),
        [d, u]
      );
    };
  };
  return t.scopeName = e, [n, je(t, ...o)];
}
function je(...e) {
  const o = e[0];
  if (e.length === 1) return o;
  const r = () => {
    const n = e.map((t) => ({
      useScope: t(),
      scopeName: t.scopeName
    }));
    return function(s) {
      const c = n.reduce((d, { useScope: u, scopeName: l }) => {
        const f = u(s)[`__scope${l}`];
        return { ...d, ...f };
      }, {});
      return a.useMemo(() => ({ [`__scope${o.scopeName}`]: c }), [c]);
    };
  };
  return r.scopeName = o.scopeName, r;
}
function _(e) {
  const o = a.useRef(e);
  return a.useEffect(() => {
    o.current = e;
  }), a.useMemo(() => (...r) => {
    var n;
    return (n = o.current) == null ? void 0 : n.call(o, ...r);
  }, []);
}
function I({
  prop: e,
  defaultProp: o,
  onChange: r = () => {
  }
}) {
  const [n, t] = Pe({ defaultProp: o, onChange: r }), s = e !== void 0, c = s ? e : n, d = _(r), u = a.useCallback(
    (l) => {
      if (s) {
        const f = typeof l == "function" ? l(e) : l;
        f !== e && d(f);
      } else
        t(l);
    },
    [s, e, t, d]
  );
  return [c, u];
}
function Pe({
  defaultProp: e,
  onChange: o
}) {
  const r = a.useState(e), [n] = r, t = a.useRef(n), s = _(o);
  return a.useEffect(() => {
    t.current !== n && (s(n), t.current = n);
  }, [n, t, s]), r;
}
var j = a.forwardRef((e, o) => {
  const { children: r, ...n } = e, t = a.Children.toArray(r), s = t.find(Ae);
  if (s) {
    const c = s.props.children, d = t.map((u) => u === s ? a.Children.count(c) > 1 ? a.Children.only(null) : a.isValidElement(c) ? c.props.children : null : u);
    return /* @__PURE__ */ i.jsx(y, { ...n, ref: o, children: a.isValidElement(c) ? a.cloneElement(c, void 0, d) : null });
  }
  return /* @__PURE__ */ i.jsx(y, { ...n, ref: o, children: r });
});
j.displayName = "Slot";
var y = a.forwardRef((e, o) => {
  const { children: r, ...n } = e;
  if (a.isValidElement(r)) {
    const t = Te(r);
    return a.cloneElement(r, {
      ...Oe(n, r.props),
      // @ts-ignore
      ref: o ? C(o, t) : t
    });
  }
  return a.Children.count(r) > 1 ? a.Children.only(null) : null;
});
y.displayName = "SlotClone";
var Ee = ({ children: e }) => /* @__PURE__ */ i.jsx(i.Fragment, { children: e });
function Ae(e) {
  return a.isValidElement(e) && e.type === Ee;
}
function Oe(e, o) {
  const r = { ...o };
  for (const n in o) {
    const t = e[n], s = o[n];
    /^on[A-Z]/.test(n) ? t && s ? r[n] = (...d) => {
      s(...d), t(...d);
    } : t && (r[n] = t) : n === "style" ? r[n] = { ...t, ...s } : n === "className" && (r[n] = [t, s].filter(Boolean).join(" "));
  }
  return { ...e, ...r };
}
function Te(e) {
  var n, t;
  let o = (n = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : n.get, r = o && "isReactWarning" in o && o.isReactWarning;
  return r ? e.ref : (o = (t = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : t.get, r = o && "isReactWarning" in o && o.isReactWarning, r ? e.props.ref : e.props.ref || e.ref);
}
var $e = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], Ge = $e.reduce((e, o) => {
  const r = a.forwardRef((n, t) => {
    const { asChild: s, ...c } = n, d = s ? j : o;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ i.jsx(d, { ...c, ref: t });
  });
  return r.displayName = `Primitive.${o}`, { ...e, [o]: r };
}, {}), ke = globalThis != null && globalThis.document ? a.useLayoutEffect : () => {
}, Le = a.useId || (() => {
}), Be = 0;
function D(e) {
  const [o, r] = a.useState(Le());
  return ke(() => {
    e || r((n) => n ?? String(Be++));
  }, [e]), e || (o ? `radix-${o}` : "");
}
var R = "DropdownMenu", [Ue, Do] = Ie(
  R,
  [S]
), p = S(), [We, P] = Ue(R), E = (e) => {
  const {
    __scopeDropdownMenu: o,
    children: r,
    dir: n,
    open: t,
    defaultOpen: s,
    onOpenChange: c,
    modal: d = !0
  } = e, u = p(o), l = a.useRef(null), [g = !1, f] = I({
    prop: t,
    defaultProp: s,
    onChange: c
  });
  return /* @__PURE__ */ i.jsx(
    We,
    {
      scope: o,
      triggerId: D(),
      triggerRef: l,
      contentId: D(),
      open: g,
      onOpenChange: f,
      onOpenToggle: a.useCallback(() => f((w) => !w), [f]),
      modal: d,
      children: /* @__PURE__ */ i.jsx(De, { ...u, open: g, onOpenChange: f, dir: n, modal: d, children: r })
    }
  );
};
E.displayName = R;
var A = "DropdownMenuTrigger", O = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, disabled: n = !1, ...t } = e, s = P(A, r), c = p(r);
    return /* @__PURE__ */ i.jsx(le, { asChild: !0, ...c, children: /* @__PURE__ */ i.jsx(
      Ge.button,
      {
        type: "button",
        id: s.triggerId,
        "aria-haspopup": "menu",
        "aria-expanded": s.open,
        "aria-controls": s.open ? s.contentId : void 0,
        "data-state": s.open ? "open" : "closed",
        "data-disabled": n ? "" : void 0,
        disabled: n,
        ...t,
        ref: C(o, s.triggerRef),
        onPointerDown: h(e.onPointerDown, (d) => {
          !n && d.button === 0 && d.ctrlKey === !1 && (s.onOpenToggle(), s.open || d.preventDefault());
        }),
        onKeyDown: h(e.onKeyDown, (d) => {
          n || (["Enter", " "].includes(d.key) && s.onOpenToggle(), d.key === "ArrowDown" && s.onOpenChange(!0), ["Enter", " ", "ArrowDown"].includes(d.key) && d.preventDefault());
        })
      }
    ) });
  }
);
O.displayName = A;
var Ke = "DropdownMenuPortal", T = (e) => {
  const { __scopeDropdownMenu: o, ...r } = e, n = p(o);
  return /* @__PURE__ */ i.jsx(Se, { ...n, ...r });
};
T.displayName = Ke;
var $ = "DropdownMenuContent", G = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, ...n } = e, t = P($, r), s = p(r), c = a.useRef(!1);
    return /* @__PURE__ */ i.jsx(
      fe,
      {
        id: t.contentId,
        "aria-labelledby": t.triggerId,
        ...s,
        ...n,
        ref: o,
        onCloseAutoFocus: h(e.onCloseAutoFocus, (d) => {
          var u;
          c.current || (u = t.triggerRef.current) == null || u.focus(), c.current = !1, d.preventDefault();
        }),
        onInteractOutside: h(e.onInteractOutside, (d) => {
          const u = d.detail.originalEvent, l = u.button === 0 && u.ctrlKey === !0, g = u.button === 2 || l;
          (!t.modal || g) && (c.current = !0);
        }),
        style: {
          ...e.style,
          "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
          "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
          "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
          "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
          "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
        }
      }
    );
  }
);
G.displayName = $;
var Ve = "DropdownMenuGroup", k = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
    return /* @__PURE__ */ i.jsx(me, { ...t, ...n, ref: o });
  }
);
k.displayName = Ve;
var Fe = "DropdownMenuLabel", L = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
    return /* @__PURE__ */ i.jsx(we, { ...t, ...n, ref: o });
  }
);
L.displayName = Fe;
var ze = "DropdownMenuItem", B = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
    return /* @__PURE__ */ i.jsx(ge, { ...t, ...n, ref: o });
  }
);
B.displayName = ze;
var Xe = "DropdownMenuCheckboxItem", U = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(xe, { ...t, ...n, ref: o });
});
U.displayName = Xe;
var Ze = "DropdownMenuRadioGroup", W = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(ve, { ...t, ...n, ref: o });
});
W.displayName = Ze;
var qe = "DropdownMenuRadioItem", K = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(he, { ...t, ...n, ref: o });
});
K.displayName = qe;
var Je = "DropdownMenuItemIndicator", V = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(be, { ...t, ...n, ref: o });
});
V.displayName = Je;
var Qe = "DropdownMenuSeparator", F = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(ye, { ...t, ...n, ref: o });
});
F.displayName = Qe;
var Ye = "DropdownMenuArrow", z = a.forwardRef(
  (e, o) => {
    const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
    return /* @__PURE__ */ i.jsx(Me, { ...t, ...n, ref: o });
  }
);
z.displayName = Ye;
var He = (e) => {
  const { __scopeDropdownMenu: o, children: r, open: n, onOpenChange: t, defaultOpen: s } = e, c = p(o), [d = !1, u] = I({
    prop: n,
    defaultProp: s,
    onChange: t
  });
  return /* @__PURE__ */ i.jsx(Ce, { ...c, open: d, onOpenChange: u, children: r });
}, eo = "DropdownMenuSubTrigger", X = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(Re, { ...t, ...n, ref: o });
});
X.displayName = eo;
var oo = "DropdownMenuSubContent", Z = a.forwardRef((e, o) => {
  const { __scopeDropdownMenu: r, ...n } = e, t = p(r);
  return /* @__PURE__ */ i.jsx(
    Ne,
    {
      ...t,
      ...n,
      ref: o,
      style: {
        ...e.style,
        "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
        "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
        "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
        "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
        "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
      }
    }
  );
});
Z.displayName = oo;
var ro = E, no = O, to = T, ao = G, so = k, io = L, co = B, uo = U, po = W, lo = K, q = V, fo = F, mo = z, wo = He, go = X, xo = Z;
const J = mo;
J.displayName = "Arrow";
const Q = a.forwardRef(({ className: e, children: o, checked: r, ...n }, t) => /* @__PURE__ */ i.jsxs(
  uo,
  {
    ref: t,
    className: m(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    checked: r,
    ...n,
    children: [
      /* @__PURE__ */ i.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ i.jsx(q, { children: /* @__PURE__ */ i.jsx(M, { name: "Check" }) }) }),
      o
    ]
  }
));
Q.displayName = "CheckboxItem";
const Y = a.forwardRef(({ className: e, sideOffset: o = 4, withPortal: r, ...n }, t) => {
  const s = /* @__PURE__ */ i.jsx(
    ao,
    {
      ref: t,
      sideOffset: o,
      className: m(
        "DropdownMenuContent z-dialog [data-disabled]:hidden p-4 gap-3 flex flex-col overflow-hidden overflow-y-auto scrollbar-stable rounded-md border border-gray-neutral-80 bg-popover text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        e
      ),
      ...n
    }
  );
  return r ? /* @__PURE__ */ i.jsx(ho.Portal, { children: s }) : s;
});
Y.displayName = "Content";
const H = so;
H.displayName = "Group";
const ee = a.forwardRef(({ className: e, inset: o, ...r }, n) => /* @__PURE__ */ i.jsx(
  co,
  {
    ref: n,
    className: m(
      "relative flex cursor-pointer select-none items-center rounded-sm px-2 data-[selected=true]:bg-gray-neutral-30 py-1.5 text-sm outline-none transition-colors focus:bg-gray-neutral-30 focus:text-accent-foreground hover:bg-gray-neutral-30 min-h-9 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      o && "pl-8",
      e
    ),
    ...r
  }
));
ee.displayName = "Item";
const oe = a.forwardRef(({ className: e, inset: o, ...r }, n) => /* @__PURE__ */ i.jsx(
  io,
  {
    ref: n,
    className: m(
      "px-2 py-1.5 text-sm font-semibold",
      o && "pl-8",
      e
    ),
    ...r
  }
));
oe.displayName = "Label";
const re = to;
re.displayName = "Portal";
const ne = po;
ne.displayName = "RadioGroup";
const te = a.forwardRef(({ className: e, children: o, ...r }, n) => /* @__PURE__ */ i.jsxs(
  lo,
  {
    ref: n,
    className: m(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    ...r,
    children: [
      /* @__PURE__ */ i.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ i.jsx(q, { children: /* @__PURE__ */ i.jsx(M, { name: "Circle" }) }) }),
      o
    ]
  }
));
te.displayName = "RadioItem";
const ae = a.forwardRef(({ className: e, ...o }, r) => /* @__PURE__ */ i.jsx(
  fo,
  {
    ref: r,
    className: m("-mx-1 h-px bg-gray-neutral-80", e),
    ...o
  }
));
ae.displayName = "Separator";
const se = ({
  className: e,
  ...o
}) => /* @__PURE__ */ i.jsx(
  "span",
  {
    className: m("ml-auto text-xs tracking-widest opacity-60", e),
    ...o
  }
);
se.displayName = "Shortcut";
const ie = wo;
ie.displayName = "Sub";
const de = a.forwardRef(({ className: e, ...o }, r) => /* @__PURE__ */ i.jsx(
  xo,
  {
    ref: r,
    className: m(
      "z-tooltip min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      e
    ),
    ...o
  }
));
de.displayName = "SubContent";
const ce = a.forwardRef(({ className: e, inset: o, children: r, ...n }, t) => /* @__PURE__ */ i.jsx(
  go,
  {
    ref: t,
    className: m(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-neutral-30 data-[state=open]:bg-gray-neutral-30 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      o && "pl-8",
      e
    ),
    ...n,
    children: /* @__PURE__ */ i.jsxs("div", { className: m("flex items-center justify-between w-full", e), children: [
      r,
      /* @__PURE__ */ i.jsx(M, { name: "NavArrowRight", width: 24, height: 24 })
    ] })
  }
));
ce.displayName = "SubTrigger";
const ue = no;
ue.displayName = "Trigger";
const vo = ro, ho = Object.assign(vo, {
  Arrow: J,
  Trigger: ue,
  Group: H,
  Portal: re,
  Sub: ie,
  RadioGroup: ne,
  SubTrigger: ce,
  SubContent: de,
  Content: Y,
  Item: ee,
  CheckboxItem: Q,
  RadioItem: te,
  Label: oe,
  Separator: ae,
  Shortcut: se
});
export {
  ho as D
};
